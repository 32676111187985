import { Route, Switch } from "react-router-dom";

import Inicio from "./pages/Inicio";
import AcercaDe from "./pages/AcercaDe";
import Portfolio from "./pages/Portfolio";
import Contacto from "./pages/Contacto";
import Layout from "./components/Layout/Layout";

import { ViewportProvider } from "./UI/screenContext";

function App() {
  return (
    <ViewportProvider>
      <Layout>
        <Switch>
          <Route path="/" exact>
            <Inicio />
          </Route>
          <Route path="/acercade">
            <AcercaDe />
          </Route>
          <Route path="/portfolio">
            <Portfolio />
          </Route>
          <Route path="/contacto">
            <Contacto />
          </Route>
        </Switch>
      </Layout>
    </ViewportProvider>
  );
}

export default App;
