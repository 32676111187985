import Card from "../UI/Card";

import MensajeForm from "../components/Contacto/MensajeForm";
import SectionDelimiter from "../UI/SectionDelimiter";
import classes from "./Contacto.module.css";
import Redes from "../components/Contacto/Redes";

import { useViewport } from "../UI/screenContext.js";

import logo_relleno from "../media/logoinicio.png";

import background from "../media/contacto_back.mp4";

function Contacto(props) {
  const { width } = useViewport();
  const breakpoint = 768;

  const separator = (
    <div className={classes.separator__container}>
      <div className={classes.separator__line}></div>
      <h2 className={classes.separator__text}>o</h2>
      <div className={classes.separator__line}></div>
    </div>
  );

  const logo = (
    <img className={classes.logoRelleno} src={logo_relleno} alt="Logo Lamas Web" />
  );

  const video = (
    <video className={classes.backgroundVideo} autoPlay loop muted>
      <source src={background} type="video/mp4" />
    </video>
  );

  return (
    <section className={classes.contact__container}>
      {width > breakpoint && video}
      <SectionDelimiter className={classes.contact__delimiter}>
        {width > breakpoint && logo}
        <Card className={classes.contact__card}>
          <MensajeForm className={classes.message__form} />
          {width < breakpoint && separator}
          <div>
            <h1 className={classes.contact__cardTitle}>
              Contáctame directamente
            </h1>
            <Redes color="#202020" />
          </div>
        </Card>
      </SectionDelimiter>
    </section>
  );
}

export default Contacto;
