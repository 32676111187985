import classes from "./ExperienceExpanded.module.css";

import Card from "../../../UI/Card";

function ExperienceExpanded(props) {
  return (
    <Card className={`${classes.experience__container} ${props.className}`}>
      <div className={classes.where}>{props.where}</div>
      <h3 className={classes.what}>{props.what}</h3>
      <h3 className={classes.when}>{props.when}</h3>
      <p className={classes.description}>{props.description}</p>
    </Card>
  );
}

export default ExperienceExpanded;
