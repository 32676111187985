import classes from "./AbilityExpanded.module.css";

import Card from "../../../UI/Card";

function AbilityExpanded(props) {
  return (
    <Card className={`${classes.ability__container} ${props.className}`}>
      <div className={classes.logoName__container}>
      <img
        className={classes.abilityLogo}
        src={props.img}
        alt=""
      />
      <h2 className={classes.abilityName}>{props.abilityName}</h2>
      </div>
      <div className={classes.description__container}>
        <p className={classes.description__text}>
          {props.description}
        </p>
      </div>
    </Card>
  );
}

export default AbilityExpanded;
