import classes from "./Button.module.css";

function Button(props) {
  const CSSclass = `${classes.default__button} ${props.className}`;
  return (
    <button className={CSSclass} disabled={props.disabled} onClick={props.onClick} type={props.type}>{props.children}</button>
  );
}

export default Button;
