import { Fragment, useState, useEffect } from "react";
import classes from "./ExperiencesList.module.css";
import { useViewport } from "../../../UI/screenContext";

import Modal from "../../../UI/Modal";
import Button from "../../../UI/Button";
import Card from "../../../UI/Card";
import Experience from "./Experience";

import { getExperiences } from "../../../helpers/firebase";

function ExperienceListLoaded(props) {
  const allOverlay = (
    <Modal onClick={props.showAllFalse}>
      <Card className={classes.all__card}>
        <h1 className={classes.all__header}>Experiencias</h1>
        {props.loadedExperiences.map((experience) => {
          return (
            <Experience
              key={experience.id}
              where={experience.where}
              when={experience.when}
              what={experience.what}
              description={experience.description}
            />
          );
        })}
      </Card>
    </Modal>
  );

  return (
    <Fragment>
      <ul className={classes.experiences__list}>
        {props.loadedExperiences
          .slice(0, props.numberExperiences)
          .map((experience) => {
            return (
              <Experience
                key={experience.id}
                where={experience.where}
                when={experience.when}
                what={experience.what}
                description={experience.description}
              />
            );
          })}
      </ul>
      <Button className={classes.seeAll__button} onClick={props.showAllTrue}>
        Ver todas
      </Button>
      {props.showAll && allOverlay}
    </Fragment>
  );
}

function ExperienceListLoading(props) {
  const allOverlay = (
    <Modal onClick={props.showAllFalse}>
      <Card className={classes.all__card}>
        <h1 className={classes.all__header}>Experiencias</h1>
        <h2> Cargando... </h2>
      </Card>
    </Modal>
  );

  return (
    <Fragment>
      <ul className={classes.experiences__list}>
        <h2> Cargando... </h2>
      </ul>
      <Button className={classes.seeAll__button} onClick={props.showAllTrue}>
        Ver todas
      </Button>
      {props.showAll && allOverlay}
    </Fragment>
  );
}

function ExperienciesList(props) {
  const { width } = useViewport();

  const [showAll, setShowAll] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loadedExperiences, setLoadedExperiences] = useState(null);

  const showAllTrue = () => setShowAll(true);
  const showAllFalse = () => setShowAll(false);

  useEffect(() => {
    setIsLoading(true);
    getExperiences().then((response) => {
      const experiences = [];
      response.forEach((doc) => {
        const experience = {
          id: doc.id,
          ...doc.data(),
        };
        experiences.push(experience);
      });
      setLoadedExperiences(experiences);
      setIsLoading(false);
    });
  }, []);

  let numberExperiences = 4;
  if (width < 1300) numberExperiences = 2;
  if (width < 768) numberExperiences = 3;

  if (!isLoading) {
    return (
      <ExperienceListLoaded
        loadedExperiences={loadedExperiences}
        numberExperiences={numberExperiences}
        showAll={showAll}
        showAllFalse={showAllFalse}
        showAllTrue={showAllTrue}
      />
    );
  } else
    return (
      <ExperienceListLoading
        showAll={showAll}
        showAllFalse={showAllFalse}
        showAllTrue={showAllTrue}
      />
    );
}

export default ExperienciesList;
