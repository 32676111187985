import TrabajoItem from "./TrabajoItem";

import Carousel from "../../UI/Carousel";
import TrabajoItemVacio from "./TrabajoItemVacio";

function UltimosTrabajos(props) {

  const works = props.works;

  return (
    <Carousel>
      {[...Array(6).keys()].map((index) => {
        if (index < works.length) {
          return (
            <div key={index} style={{ position: "relative" }}>
              <TrabajoItem work={works[index]} />
            </div>
          );
        } else {
          return (
            <div key={index} style={{ position: "relative" }}>
              <TrabajoItemVacio />
            </div>
          );
        }

      })}
    </Carousel>
  );
}

export default UltimosTrabajos;
