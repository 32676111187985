import classes from "./Inicio.module.css";

import background from "../media/inicio_back.mp4";
import logo_relleno from "../media/logoinicio.png";

function Inicio() {
  return (
    <section className={classes.homePage}>
      <video className={classes.backgroundVideo} autoPlay loop muted>
        <source src={background} type="video/mp4" />
      </video>
      <div className={classes.homeContent}>
          <img className={classes.logoRelleno} src={logo_relleno} alt="Logo Lamas Web" />
      </div>
    </section>
  );
}

export default Inicio;
