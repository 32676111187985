import classes from "./Acercade.module.css";
import ExperienciesList from "../components/AcercaDe/Experience/ExperienciesList";
import SectionDelimiter from "../UI/SectionDelimiter";
import Profile from "../components/AcercaDe/Profile";
import AbilitiesList from "../components/AcercaDe/Ability/AbilitiesList";
import Card from "../UI/Card";

import background from "../media/acercade_back.mp4";

import { useViewport } from "../UI/screenContext.js";

function AcercaDe() {
  const { width } = useViewport();
  const breakpoint = 768;

  const video = (
    <video className={classes.backgroundVideo} autoPlay loop muted>
      <source src={background} type="video/mp4" />
    </video>
  );

  return (
    <section className={classes.about__container}>
      {width > breakpoint ? video : null}
      <SectionDelimiter className={classes.delimiter__section}>
        <Profile />
        <Card className={classes.curriculum__container}>
          <div className={classes.abilExp__container}>
            <div className={classes.abilitiesList__container}>
              <h1 className={classes.curriculum__title}>Servicios</h1>
              <AbilitiesList />
            </div>
            <div className={classes.experiencesList__container}>
              <h1 className={classes.curriculum__title}>Titulaciones</h1>
              <ExperienciesList />
            </div>
          </div>
        </Card>
      </SectionDelimiter>
    </section>
  );
}

export default AcercaDe;
