import background from "../media/inicio_back.mp4";
import classes from "./Portfolio.module.css";

import { useState, useEffect, useRef, Fragment } from "react";

import { BsChevronCompactDown } from "react-icons/bs";

import UltimosTrabajos from "../components/Trabajos/UltimosTrabajos";
import LinkButton from "../UI/LinkButton";
import Button from "../UI/Button";
import TrabajoLista from "../components/Trabajos/TrabajoLista";
import Card from "../UI/Card";

import { getWorks } from "../helpers/firebase";

const TrailerAccess = () => {
  const trailerAccessContent = (
    <Fragment>
      <h3 className={classes.trailer__text}>¿Quieres ver mi último trabajo?</h3>
      <LinkButton
        href="https://www.facebook.com/ValdovinoTurismo/videos/251859783290229/"
        className={classes.seeTrailer__button}
      >
        Pulsa aquí
      </LinkButton>
    </Fragment>
  );
  return (
    <Card className={classes.trailerAccess__card}>{trailerAccessContent}</Card>
  );
};

function Portfolio() {
  const [isLoading, setIsLoading] = useState(true);
  const [loadedWorks, setLoadedWorks] = useState([]);
  const [seeAll, setSeeAll] = useState(false);

  const allWorkRef = useRef();
  const allWorkListRef = useRef();

  useEffect(() => {
    setIsLoading(true);
    getWorks().then((response) => {
      const works = [];
      response.forEach((doc) => {
        const work = {
          id: doc.id,
          ...doc.data(),
        };
        works.push(work);
      });
      setIsLoading(false);
      setLoadedWorks(works);
    });
  }, []);

  const executeFirstScroll = () => allWorkRef.current.scrollIntoView();
  const executeSecondScroll = () => allWorkListRef.current.scrollIntoView();

  const seeAllHandler = () => {
    setSeeAll((prevState) => !prevState);
    if (!seeAll) executeSecondScroll();
  };

  return (
    <section className={classes.portfolio__container}>
      <div className={classes.trailer__container}>
        <video className={classes.trailer__video} autoPlay loop muted>
          <source src={background} type="video/mp4" />
        </video>
        <TrailerAccess />
        <div className={classes.scroll__container} onClick={executeFirstScroll}>
          <h4 className={classes.scroll__text}>Otros trabajos</h4>
          <button className={classes.scroll__button}>
            <BsChevronCompactDown className={classes.scroll__icon} />
          </button>
        </div>
      </div>
      <div className={classes.allWork__container} ref={allWorkRef}>
        <h1 className={classes.lastWork__title}>Últimos trabajos</h1>
        <UltimosTrabajos isLoading={isLoading} works={loadedWorks} />
        <Button className={classes.seeAll__button} onClick={seeAllHandler}>
          {!seeAll ? "Ver todos" : "Cerrar"}
        </Button>
      </div>
      <div ref={allWorkListRef}>{seeAll && <TrabajoLista />}</div>
    </section>
  );
}

export default Portfolio;
