import Button from "../../UI/Button";

import classes from "./MensajeForm.module.css";

import useInput from "../../hooks/use-input";
import { sendMail } from "../../helpers/firebase";
import { useState, Fragment } from "react";

import Modal from "./../../UI/Modal";
import Card from "./../../UI/Card";

import okeyImg from "./../../media/okey.png";
import errorImg from "./../../media/error.png";

function MensajeForm(props) {
  const [emailSentError, setEmailSentError] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);

  const {
    value: enteredEmail,
    hasError: emailInputHasError,
    isValid: emailInputIsValid,
    valueChangeHandler: emailInputChangeHandler,
    inputBlurHandler: emailInputBlurHandler,
    reset: emailInputReset,
  } = useInput((value) => value.trim() !== "" && value.includes("@"));

  const {
    value: enteredText,
    hasError: textInputHasError,
    isValid: textInputIsValid,
    valueChangeHandler: textInputChangeHandler,
    inputBlurHandler: textInputBlurHandler,
    reset: textInputReset,
  } = useInput((value) => value.trim() !== "");

  const {
    value: enteredSubject,
    hasError: subjectInputHasError,
    isValid: subjectInputIsValid,
    valueChangeHandler: subjectInputChangeHandler,
    inputBlurHandler: subjectInputBlurHandler,
    reset: subjectInputReset,
  } = useInput((value) => value.trim() !== "");

  let formIsValid = false;
  if (subjectInputIsValid && emailInputIsValid && textInputIsValid) {
    formIsValid = true;
  }

  const submitHandler = (event) => {
    event.preventDefault();

    if (!formIsValid) {
      return;
    }

    const email = {
      replyTo: enteredEmail,
      subject: enteredSubject,
      text: enteredText,
    };

    sendMail(email, setEmailSentError, setShowEmailModal);

    emailInputReset();
    textInputReset();
    subjectInputReset();
  };

  const subjectInputClass = !subjectInputHasError
    ? classes.message__input
    : `${classes.message__input} ${classes.invalid}`;
  const emailInputClass = !emailInputHasError
    ? classes.message__input
    : `${classes.message__input} ${classes.invalid}`;
  const textInputClass = !textInputHasError
    ? classes.message__input
    : `${classes.message__input} ${classes.invalid}`;

  const subjectError = (
    <span className={classes.error__text}> *Es necesario poner un asunto</span>
  );
  const emailError = (
    <span className={classes.error__text}>
      {" "}
      *Es necesario poner un email válido
    </span>
  );
  const textError = (
    <span className={classes.error__text}> *Es necesario añadir texto</span>
  );

  const modalEmailSent = (
    <Modal onClick={() => setShowEmailModal(false)}>
      <Card className={classes.emailSent__container}>
        <h1 >{emailSentError ? "Error, prueba más tarde" : "¡E-Mail enviado!"}</h1>
        <img className={classes.emailSent__icon} src={emailSentError ? errorImg : okeyImg} alt="img error"/>
      </Card>
    </Modal>
  );

  return (
    <Fragment>
      <div className={props.className}>
        <h1 className={classes.contact__title}>Envíame un mensaje</h1>
        <form
          onSubmit={submitHandler}
          className={classes.message__form}
          id="messageForm"
        >
          <label htmlFor="email">
            E-Mail
            {emailInputHasError && emailError}
          </label>
          <input
            className={emailInputClass}
            type="text"
            placeholder="ejemplo@ejemplo.com"
            id="email"
            onChange={emailInputChangeHandler}
            onBlur={emailInputBlurHandler}
            value={enteredEmail}
          />
          <label htmlFor="subject">
            Asunto
            {subjectInputHasError && subjectError}
          </label>
          <input
            className={subjectInputClass}
            type="text"
            id="subject"
            onChange={subjectInputChangeHandler}
            onBlur={subjectInputBlurHandler}
            value={enteredSubject}
          />
          <label htmlFor="message">
            Mensaje
            {textInputHasError && textError}
          </label>
          <textarea
            className={textInputClass}
            rows={4}
            placeholder="Escríbeme un mensaje"
            form="messageForm"
            onChange={textInputChangeHandler}
            onBlur={textInputBlurHandler}
            value={enteredText}
          />
          <Button disabled={!formIsValid} type="submit">
            Enviar
          </Button>
        </form>
      </div>
      {showEmailModal && modalEmailSent}
    </Fragment>
  );
}

export default MensajeForm;
