import classes from "./TrabajoLista.module.css";

function TrabajoLista() {
  return (
    <div className={classes.allWorks__container}>
      <p className={classes.message__text}>Por ahora, los trabajos que veis arriba son los disponibles.</p>
    </div>
  );
}

export default TrabajoLista;
