import classes from "./Profile.module.css";
import { useViewport } from "../../UI/screenContext";

const Profile = () => {
  const { width } = useViewport();
  const breakpoint = 768;

  const description = (
    <div className={classes.profile__description}>
      <h1 className={classes.profile__name}>Álvaro Núñez Lamas</h1>
      <h2>Freelance filmmaker</h2>
      <p className={classes.profile__summary}>
        Álvaro Lamas, joven realizador audiovisual especializado en la creación
        de vídeos corporativos sostenidos por guiones originales y adaptados a
        la necesidad del cliente. Más allá de la calidad audiovisual el
        propósito que guía los proyectos de Lamas Visual es contar historias y
        emocionar al espectador con ideas únicas.
      </p>
    </div>
  );

  return (
    <div className={classes.profile__superContainer}>
      <div className={classes.profile__container}>
        {width < breakpoint && description}
      </div>
      {width >= breakpoint && description}
    </div>
  );
};

export default Profile;
