import { Fragment } from "react";
import reactDom from "react-dom";

import classes from "./Backdrop.module.css";

function Backdrop(props) {
  const CSSClass = `${classes.backdrop} ${props.className}`;
  return (
    <Fragment>
      {reactDom.createPortal(
        <div className={CSSClass} onClick={props.onClick}></div>,
        document.getElementById("backdrop-root")
      )}
    </Fragment>
  );
}

export default Backdrop;
