import { useState } from "react";
import TinySlider from "tiny-slider-react";
import "tiny-slider/dist/tiny-slider.css";

import { useViewport } from "./screenContext";

import leftArrow from "../media/chevron-left-solid.svg";
import rightArrow from "../media/chevron-right-solid.svg";

import classes from "./Carousel.module.css";

const settings = {
  lazyload: true,
  nav: false,
  mouseDrag: true,
  items: 1,
  controls: false,
  gutter: 16,
  loop: false,
  responsive: {
    768: {
      items: 3,
    },
  },
};

const Carousel = (props) => {
  const { width } = useViewport();
  const breakpoint = 768;

  const [isFirstSlide, setIsFirstSlide] = useState(true);
  const [isLastSlide, setIsLastSlide] = useState(false);

  let slide;

  const onGoTo = (dir) => {
    slide.slider.goTo(dir);
    if (dir === "prev") {
      onFirstSlide();
      if (isLastSlide === true) setIsLastSlide(false);
    }

    if (dir === "next") {
      onLastSlide();
      if (isFirstSlide === true) setIsFirstSlide(false);
    }
  };

  const onFirstSlide = () => {
    if (slide.slider.getInfo().index === 0) setIsFirstSlide(true);
    else setIsFirstSlide(false);
  };
  const onLastSlide = () => {
    console.log(slide.slider.getInfo().index);
    if (slide.slider.getInfo().index === 3) setIsLastSlide(true);
    else setIsLastSlide(false);
  };

  const buttonPrev = (
    <button
      className={`${classes.carousel__button} ${
        isFirstSlide && classes.disabled
      }`}
      onClick={() => onGoTo("prev")}
    >
      <img src={leftArrow} alt="left arrow"/>
    </button>
  );

  const buttonNext = (
    <button
      className={`${classes.carousel__button} ${
        isLastSlide && classes.disabled
      }`}
      onClick={() => onGoTo("next")}
    >
      <img src={rightArrow} alt="right arrow"/>
    </button>
  );

  return (
    <div className={classes.carousel__container}>
      {width > breakpoint && buttonPrev}
      <div className={classes.carouselWrapper}>
        <TinySlider settings={settings} ref={(ts) => (slide = ts)}>
          {props.children}
        </TinySlider>
      </div>
      {width > breakpoint && buttonNext}
    </div>
  );
};

export default Carousel;
