import { Fragment, useState } from "react";
import classes from "./Experience.module.css";

import Modal from "../../../UI/Modal";
import ExperienceExpanded from "./ExperienceExpanded";


function Experience(props) {
  const [showFullExperience, setShowFullExperience] = useState(false);

  const showFullExperienceTrue = () => {
    setShowFullExperience(true);
  };

  const showFullExperienceFalse = () => {
    setShowFullExperience(false);
  };

  const cardCompleteExperience = (
    <Modal
      modalClass={classes.expanded__modal}
      backdropClass={classes.expanded__backdrop}
      onClick={showFullExperienceFalse}
    >
      <ExperienceExpanded
        where={props.where}
        when={props.when}
        what={props.what}
        description={props.description}
      />
    </Modal>
  );

  return (
    <Fragment>
      <li className={classes.experiences__item}>
        <div className={classes.where}>{props.where}</div>
        <h3 className={classes.what}>{props.what}</h3>
        <h3 className={classes.when}>{props.when}</h3>
        <p className={classes.description}>{props.description}</p>
        <button className={classes.seeMore__button} onClick={showFullExperienceTrue}>
          Ver más
        </button>
      </li>
      {showFullExperience && cardCompleteExperience}
    </Fragment>
  );
}

export default Experience;
