import { Link, NavLink } from "react-router-dom";
import { Fragment, useState } from "react";

import classes from "./MainNavigation.module.css";

import { useViewport } from "../../UI/screenContext";
import Backdrop from "../../UI/Backdrop";

import logo from "../../media/LOGOBLANCOWEB.png";

const Mobile = () => {
  const [isOpen, setIsOpen] = useState(false);

  const navHandler = () => {
    setIsOpen((prevState) => {
      return !prevState;
    });
  };

  const nav = (
    <Fragment>
      <nav className={classes.mainNav}>
        <NavLink
          exact
          to={"/"}
          className={classes.mainNav__item}
          activeClassName={classes.mainNav__itemSelected}
          onClick={navHandler}
        >
          Inicio
        </NavLink>
        <NavLink
          to={"/portfolio"}
          className={classes.mainNav__item}
          activeClassName={classes.mainNav__itemSelected}
          onClick={navHandler}
        >
          Portfolio
        </NavLink>
        <NavLink
          to={"/acercade"}
          className={classes.mainNav__item}
          activeClassName={classes.mainNav__itemSelected}
          onClick={navHandler}
        >
          Acerca De
        </NavLink>
        <NavLink
          to={"/contacto"}
          className={classes.mainNav__item}
          activeClassName={classes.mainNav__itemSelected}
          onClick={navHandler}
        >
          Contacto
        </NavLink>
      </nav>
      <Backdrop onClick={navHandler} />
    </Fragment>
  );

  return (
    <Fragment>
      <header className={classes.mainHeader}>
        <div>
          <button className={classes.toggleButton} onClick={navHandler}>
            <span className={classes.toggleButton__bar}></span>
            <span className={classes.toggleButton__bar}></span>
            <span className={classes.toggleButton__bar}></span>
          </button>
        </div>
        <div>
          <Link className={classes.mainHeader__brand} to={"/"}>
            <img src={logo} alt="lamas studio" />
          </Link>
        </div>
      </header>
      {isOpen && nav}
    </Fragment>
  );
};

const Desktop = () => {
  return (
    <header className={classes.mainHeader}>
      <div>
        <NavLink className={classes.mainHeader__brand} to={"/"} exact>
          <img src={logo} alt="lamas studio" />
        </NavLink>
      </div>
      <nav className={classes.mainNav}>
        <NavLink
          exact
          to={"/"}
          className={classes.mainNav__item}
          activeClassName={classes.mainNav__itemSelected}
        >
          Inicio
        </NavLink>
        <NavLink
          to={"/portfolio"}
          className={classes.mainNav__item}
          activeClassName={classes.mainNav__itemSelected}
        >
          Portfolio
        </NavLink>
        <NavLink
          to={"/acercade"}
          className={classes.mainNav__item}
          activeClassName={classes.mainNav__itemSelected}
        >
          Acerca De
        </NavLink>
        <NavLink
          to={"/contacto"}
          className={classes.mainNav__item}
          activeClassName={classes.mainNav__itemSelected}
        >
          Contacto
        </NavLink>
      </nav>
    </header>
  );
};

const MainNavigation = () => {
  const { width } = useViewport();
  const breakpoint = 768;

  return <Fragment>{width < breakpoint ? <Mobile /> : <Desktop />}</Fragment>;
};

export default MainNavigation;
