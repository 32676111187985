import classes from "./Ability.module.css";

import Card from "../../../UI/Card";
import Modal from "../../../UI/Modal";
import AbilityExpanded from "./AbilityExpanded";
import { getImage } from "../../../helpers/firebase";

import { useEffect, useState, Fragment } from "react";

function Ability(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [iconURL, setIconURL] = useState(null);
  const [showFullAbility, setShowFullAbility] = useState(false);

  const showFullAbilityTrue = () => {
    setShowFullAbility(true);
  };

  const showFullAbilityFalse = () => {
    setShowFullAbility(false);
  };

  useEffect(() => {
    setIsLoading(true);
    getImage(props.imgURL, setIconURL, setIsLoading);
  }, [props.imgURL]);

  if (!isLoading) {
    const cardCompleteAbility = (
      <Modal
        modalClass={classes.expanded__modal}
        backdropClass={classes.expanded__backdrop}
        onClick={showFullAbilityFalse}
      >
        <AbilityExpanded
          img={iconURL}
          abilityName={props.abilityName}
          description={props.description}
        />
      </Modal>
    );

    return (
      <Fragment>
        <Card
          className={classes.containerHabilidad}
          onClick={showFullAbilityTrue}
        >
          <img
            src={iconURL}
            className={classes.logoHabilidad}
            alt=""
          />
          <p className={classes.nombreHabilidad}>{props.abilityName}</p>
        </Card>
        {showFullAbility && cardCompleteAbility}
      </Fragment>
    );
  } else return <></>;
}

export default Ability;
