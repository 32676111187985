import classes from "./Footer.module.css";

import { Link } from "react-router-dom";

import Redes from "../Contacto/Redes";

function Footer() {
  return (
    <footer className={classes.mainFooter}>
      <h3 className={classes.mainFooter__header}>
        <Link className={classes.mainFooter__link} to={"/contacto"}>
          CONTACTO
        </Link>
      </h3>
      <Redes />
      <div className={classes.mainFooter__maker}>
        <p>
          Página hecha por Jorge Vázquez Pérez. Visita mi{" "}
          <a href="https://www.linkedin.com/in/jorge-v%C3%A1zquez-p%C3%A9rez-778497200/">LinkedIn</a>
        </p>
      </div>
    </footer>
  );
}
/**<Container className="m-0 p-0" fluid>
      <Card className="text-center w-100">
        <Card.Body>
          <Card.Title>¡Sígueme en mis redes!</Card.Title>
          <Card.Text>
            <AiOutlineInstagram style={{width: "30px", height: "30px"}}/>
            <AiOutlineLinkedin style={{width: "30px", height: "30px"}} />
            <AiOutlineTwitter style={{width: "30px", height: "30px"}} />
          </Card.Text>
          <Button variant="primary">¡Contáctame!</Button>
        </Card.Body>
        <Card.Footer className="text-muted">Done by Jorge Vázquez</Card.Footer>
      </Card>
    </Container> */
export default Footer;
