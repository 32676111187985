import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCczDDIyQn3aR2s0UhIwsmaoKITBp5BZtE",
  authDomain: "lamas-visual-d46e4.firebaseapp.com",
  projectId: "lamas-visual-d46e4",
  storageBucket: "lamas-visual-d46e4.appspot.com",
  messagingSenderId: "179137044009",
  appId: "1:179137044009:web:0aac1a9759685ced980638",
  measurementId: "G-K6CGMM1MLQ",
};

// Initialize Firebase
initializeApp(firebaseConfig);

const db = getFirestore();

export const getWorks = () => {
  return getDocs(collection(db, "works"));
};

export const getAbilities = () => {
  return getDocs(collection(db, "abilities"));
};

export const getImage = async (path, setURL, setIsLoading) => {
  const storage = getStorage();
  const fileRef = ref(storage, path);
  const url = await getDownloadURL(fileRef);
  setURL(url);
  setIsLoading(false);
};

export const getWorkBackground = async (path, setBackground, setIsLoading) => {
  const storage = getStorage();
  const fileRef = ref(storage, path);
  const url = await getDownloadURL(fileRef);
  setBackground({ backgroundImage: `url(${url})` });
  setIsLoading(false);
};

export const getExperiences = async () => {
  return await getDocs(collection(db, "experiences"));
};

export const sendMail = async (emailData, setEmailSentError, setShowEmailModal) => {
  fetch(
    "https://us-central1-lamas-visual-d46e4.cloudfunctions.net/emailSender",
    {
      method: "POST",
      body: JSON.stringify(emailData),
      headers: {
        "Content-Type": "application/json",
      },
    }
  ).then(() => {
    console.log("hola");
    setShowEmailModal(true);
  }).catch(() => {
    setEmailSentError(true);
  });
};
