import LinkButton from "../../UI/LinkButton";
import Card from "../../UI/Card";
import classes from "./TrabajoItem.module.css";
import { useState, useEffect } from "react";

import { getImage } from "./../../helpers/firebase";

function TrabajoItem(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [backgroundURL, setBackgroundURL] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    if(props.work.image !== "none"){
      getImage(props.work.image, setBackgroundURL, setIsLoading);
    }
  }, [props.work.image]);

  if (!isLoading) {
    return (
      <Card className={classes.item__card} style={{backgroundImage: `url(${backgroundURL})`}}>
        <LinkButton
          href={props.work.youtubeLink}
          className={classes.seeVideo__button}
        >
          Ver Video
        </LinkButton>
        <div className={classes.workSummary__container} >
          <h1>{props.work.title}</h1>
          <p>{props.work.description}</p>
        </div>
      </Card>
    );
  } else return <></>;
}

/**/
export default TrabajoItem;
