import { Fragment } from "react";
import reactDom from "react-dom";

import classes from "./Modal.module.css";
import cross from "../media/xmark-solid.svg";

import Backdrop from "./Backdrop";

function Modal(props) {

  const CSSClass = `${classes.modal__container} ${props.modalClass} `;

  const modal = (
    <div className={CSSClass}>
      <img className={classes.cross} src={cross} alt="close cross" onClick={props.onClick}/>
      {props.children}
    </div>
  );

  return (
    <Fragment>
      <Backdrop className={props.backdropClass} onClick={props.onClick} />
      {reactDom.createPortal(modal, document.getElementById("overlay-root"))}
    </Fragment>
  );
}

export default Modal;
