import classes from "./Redes.module.css";

import {
  AiOutlineInstagram,
  AiOutlineLinkedin,
  AiOutlineTwitter,
} from "react-icons/ai";

function Redes(props) {

  const textColor = {
    color: props.color,
  }

  return (
    <div className={classes.redes__container}>
      <div className={classes.contact__text} style = {textColor}>
        <p> info@lamasvisual.es</p>
        <p> +34 677 883 125</p>
      </div>
      <nav className={classes.redes__nav}>
        <ul className={classes.redes__list}>
          <li>
            <a
              className={classes.redes__link}
              style = {textColor}
              href="https://www.instagram.com/lamasvisual?r=nametag"
            >
              <AiOutlineInstagram className={classes.redes__logo} />
            </a>
          </li>
          <li>
            <a
              className={classes.redes__link}
              style = {textColor}
              href="https://www.linkedin.com/in/%C3%A1lvaro-n%C3%BA%C3%B1ez-lamas-464a34252/"
            >
              <AiOutlineLinkedin className={classes.redes__logo} />
            </a>
          </li>
          <li >
            <a
              className={classes.redes__link}
              style = {{...textColor, pointerEvents: "none"}}
              href="https://twitter.com/"
            >
              <AiOutlineTwitter className={classes.redes__logo} />
            </a>
          </li>
        </ul>
      </nav>
    </div>
    
  );
}

export default Redes;
