import LinkButton from "../../UI/LinkButton";
import Card from "../../UI/Card";
import classes from "./TrabajoItemVacio.module.css";

function TrabajoItemVacio(props) {
  return (
    <Card className={classes.item__card}>
      <LinkButton
        href="https://www.youtube.com"
        className={classes.seeVideo__button}
      >
        Ver Video
      </LinkButton>
      <div className={classes.workSummary__container}/>
    </Card>
  );
}

export default TrabajoItemVacio;
