import classes from "./AbilitiesList.module.css";

import Ability from "./Ability";
import Button from "../../../UI/Button";

import { useViewport } from "../../../UI/screenContext";
import { Fragment, useState, useEffect } from "react";
import Modal from "../../../UI/Modal";
import Card from "../../../UI/Card";

import { getAbilities } from "../../../helpers/firebase";

function AbilityListLoaded(props) {
  const allOverlay = (
    <Modal onClick={props.showAllFalse}>
      <Card className={classes.all__card}>
        <h1 className={classes.all__header}>Habilidades</h1>
        {props.loadedAbilities.map((ability) => (
          <Ability
            key={ability.id}
            imgURL={ability.image_path}
            abilityName={ability.name}
            description={ability.description}
          />
        ))}
      </Card>
    </Modal>
  );

  return (
    <Fragment>
      <div className={classes.abilities__container}>
        {props.loadedAbilities
          .slice(0, props.numberAbilities)
          .map((ability) => (
            <Ability
              key={ability.id}
              imgURL={ability.image_path}
              abilityName={ability.name}
              description={ability.description}
            />
          ))}
      </div>
      <Button className={classes.seeAll__button} onClick={props.showAllTrue}>
        Ver todas
      </Button>
      {props.showAll && allOverlay}
    </Fragment>
  );
}

function AbilityListLoading(props) {
  const allOverlay = (
    <Modal onClick={props.showAllFalse}>
      <Card className={classes.all__card}>
        <h1 className={classes.all__header}>Habilidades</h1>
        <h2> Cargando... </h2>
      </Card>
    </Modal>
  );

  return (
    <Fragment>
      <div className={classes.abilities__container}>
        <h2>Cargando...</h2>
      </div>
      <Button className={classes.seeAll__button} onClick={props.showAllTrue}>
        Ver todas
      </Button>
      {props.showAll && allOverlay}
    </Fragment>
  );
}

function AbilitiesList(props) {
  const { width } = useViewport();

  const [showAll, setShowAll] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loadedAbilities, setLoadedAbilities] = useState(null);

  const showAllTrue = () => setShowAll(true);
  const showAllFalse = () => setShowAll(false);

  useEffect(() => {
    setIsLoading(true);
    getAbilities().then((response) => {
      const abilities = [];
      response.forEach((doc) => {
        const ability = {
          id: doc.id,
          ...doc.data(),
        };
        abilities.push(ability);
      });
      setLoadedAbilities(abilities);
      setIsLoading(false);
    });
  }, []);

  let numberAbilities = 5;
  if (width < 1470) numberAbilities = 4;
  if (width < 960) numberAbilities = 3;
  if (width < 768) numberAbilities = 5;

  if (!isLoading) {
    return (
      <AbilityListLoaded
        loadedAbilities={loadedAbilities}
        numberAbilities={numberAbilities}
        showAll={showAll}
        showAllFalse={showAllFalse}
        showAllTrue={showAllTrue}
      />
    );
  } else
    return (
      <AbilityListLoading
        showAll={showAll}
        showAllFalse={showAllFalse}
        showAllTrue={showAllTrue}
      />
    );
}

export default AbilitiesList;
